<template>
    <div class="wrapper">
        <div id="footer-areas">
            <!-- Black Simple Footer With Social & Brand -->
            <footer class="footer footer-blue">
                <ul class="footer-phone">
                    <li>
                        Phone: 1-855-959-4400
                    </li>
                    <li>
                        <img
                            src="../assets/img/logo_circle_no_background.png"
                            alt="Circle image"
                            class="img-fluid rounded-circle shadow"
                            style="width: 50px; margin-left: 20px;"
                        >
                    </li>
                </ul>
            </footer>
            <!-- end Black Simple Footer With Social & Brand -->
        </div>
    </div>
</template>

<script>
export default {};
</script>
<style>
.footer-phone {
    font-family: ubuntu;
    font-size: 20px;
    color: #FFFFFF !important;
    margin-right: 4vh;
    margin-left: auto;
}
</style>
