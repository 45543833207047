<template>
  <div>
    <Header></Header>
    <SuccessContent></SuccessContent>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/Header";
import SuccessContent from "../components/SuccessContent";
import Footer from "../components/Footer";

export default {
  name: "Success",
  components: {
    Header,
    SuccessContent,
    Footer
  }
};
</script>
