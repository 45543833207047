<template>
  <div id="main-panel" class="main">
    <div class="content">
        <main-form></main-form>
<!--      <div class="content-text">-->
<!--        <b>Welcome to SOS (Safely Opening School)</b>, a program to keep schools across the United States-->
<!--        open, safe and available to every student and family. The program will help your school district-->
<!--        access the critical capital required to meet the health care needs for all schools in your district.-->
<!--      </div>-->
<!--      <div class="content-text">-->
<!--        SOS will compile all the information collected from you and others in your district.-->
<!--        Once complete, we will create the funding application with your District Superintendent-->
<!--        to access the funding provide by federal and state governments for <b>SOS program</b>.-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>

export default {
  name: "Index",
  components: {
    mainForm : () => import('./landing/MainForm')
  },
};
</script>
<style lang="scss" scoped>
.content {
  background-image: url("../assets/img/index_background.svg");
  background-position: 0% -25%;
  background-repeat: repeat-x;
}

.content-text {
    position: relative;
    top: 150px;
    left: 30vh;
    width: 70%;
    height: 380px;
    font-size: 30px;
    line-height: 2em;
}
</style>
