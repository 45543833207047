// =========================================================
// * Vue Material Kit PRO - v1.3.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-kit-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import App from "./App.vue";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from "./router";

import MaterialKit from "./plugins/material-kit";
import VueFormWizard from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import VueSimpleAlert from "vue-simple-alert";
import Vuelidate from 'vuelidate'

Vue.use(Vuelidate)
Vue.use(VueSimpleAlert);
Vue.use(VueFormWizard);

Vue.config.productionTip = false;

Vue.use(MaterialKit);
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';

Vue.use(BootstrapVue);
new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
