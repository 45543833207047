<template>
  <div id="main-panel" class="main">
    <div class="content">
     <div class="content-text text-center">
        <b>
            Thank you for completing the SOS questionnaire.<br/>
            We you will receive an estimate and list of the<br/>
            product or services you have requested.  <br/>
            An SOS representative, or a vendor representative,<br/>
            will contact you within the coming weeks <br/>
            to confirm your requests.
        </b>
      </div>
        <div class="button-container">
          <md-button
            @click="goHome"
          > Return
          </md-button>
        </div>
    </div>
  </div>
</template>

<script>
import router from '@/router'

export default {
  name: "Index",
  components: {

  },

  methods: {
      goHome() {
          router.push({ name: 'index' })
      }
  }
};
</script>
<style lang="scss" scoped>
.content {
  background-image: url("../assets/img/index_background.svg");
  background-position: 0% -5%;
  background-repeat: repeat-x;
}

.content-text {
    position: relative;
    top: 150px;
    height: 580px;
    font-size: 30px;
    line-height: 2em;
}
</style>
